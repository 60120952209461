var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"dark":"","color":"indigo"},on:{"click":function($event){return _vm.$refs.dialogSendEmail.show()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-email")])],1)]}}])},[_c('span',[_vm._v("Send email")])]),_c('tutti-dialog',{ref:"dialogSendEmail",attrs:{"title":"Send Email to Workers","maxWidth":"800","persistent":"","actions":[
            {
                label: 'Send',
                color: 'indigo darken-1',
                dark: true,
                onclick: _vm.sendEmail
            },
            {
                label: 'Cancel',
                color: 'grey darken-1',
                text: true
            }
        ]},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('v-combobox',{attrs:{"dense":"","multiple":"","small-chips":"","outlined":"","hide-selected":"","items":_vm.wids,"label":"To","search-input":_vm.searchedWorkerId},on:{"update:searchInput":function($event){_vm.searchedWorkerId=$event},"update:search-input":function($event){_vm.searchedWorkerId=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" No results matching \""),_c('strong',[_vm._v(_vm._s(_vm.searchedWorkerId))]),_vm._v("\". Press "),_c('kbd',[_vm._v("enter")]),_vm._v(" to create a new one ")])],1)],1)]},proxy:true}]),model:{value:(_vm.email.WorkerIds),callback:function ($$v) {_vm.$set(_vm.email, "WorkerIds", $$v)},expression:"email.WorkerIds"}}),_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"Subject"},model:{value:(_vm.email.Subject),callback:function ($$v) {_vm.$set(_vm.email, "Subject", $$v)},expression:"email.Subject"}}),_c('v-textarea',{attrs:{"outlined":"","label":"Message"},model:{value:(_vm.email.MessageText),callback:function ($$v) {_vm.$set(_vm.email, "MessageText", $$v)},expression:"email.MessageText"}})]},proxy:true}])}),_c('tutti-snackbar',{ref:"snackbar"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }